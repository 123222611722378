.toggle-mode {
  border: solid 0px red;

  input[type="checkbox"] {
    cursor: pointer;
    position: relative;
    display: inline-block;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    height: 30px;
    width: 110px;
    border-radius: 1.5em;
    background-color: #000;
    border-color: transparent;
    background-clip: padding-box;
    color: #e2e5e5;
    vertical-align: middle;

    &::before {
      content: "";
      position: absolute;
      top: 1px;
      left: 2px;
      bottom: 0;
      background-color: white;
      border-radius: 100%;
      border: 0.125em solid transparent;
      background-clip: padding-box;
      transform-origin: right center;
      transform: translateZ(0);
      backface-visibility: hidden;
      z-index: 2;
      width: 23px;
      height: 23px;
      transition: all 0.2s ease;
    }

    &::after {
      position: absolute;
      left: 29px;
      top: 2px;
      line-height: 2;
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      content: "CONTROL";
      letter-spacing: 0;
      word-spacing: 5px;
      z-index: 1;
    }

    &:focus {
      color: white;
      border-color: transparent;
      outline: none;
    }

    &:checked {
      color: white;
      background-color: @color-primary-color;
      border-color: transparent;

      &:after {
        content: "DESIGN";
      }

      &::before {
        transform-origin: left center;
        left: calc(100% - 27px);
      }
    }
  }
}
