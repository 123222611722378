/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-device-width: 768px) and (max-device-width: 1024px)  {
  
  // COLOR PICKERS
  .navigation-list-panels {
    .color-picker {
      &-container {
        width: 260px;
      }
    }
  }

  // WIDGET DRAWER
  .items-list, .layout-widgets {
    .item-label {
      display: none;
    }
  }
  
}