@keyframes loadingIcon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#top-bar {
  height: 50px;
  width: 100%;
  grid-column: 1 / span 3;
  display: flex;
  align-items: center;
}
#top-bar .app-icon {
  width: 50px;
  filter: invert(100%) sepia(99%) saturate(0%) hue-rotate(15deg) brightness(104%) contrast(100%);
  margin: 0px 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#top-bar .tabs-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-left: 1rem;
}
#top-bar .tabs-wrapper .tabs-wrapper-inner {
  width: calc(100% - 300px);
}
#top-bar .tabs-wrapper .tabs-wrapper-inner .tabs-drop-list {
  display: flex;
  width: 100%;
  overflow: auto;
  position: relative;
}
#top-bar .tabs-wrapper .tab {
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 20px;
  background-color: #242424;
  height: 50px;
  color: #cdcccc;
  min-width: 90px;
  max-width: 150px;
  flex: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}
#top-bar .tabs-wrapper .tab .icon {
  margin: 0px 10px 0px 0px ;
}
#top-bar .tabs-wrapper .tab.selected {
  background-color: #161616;
  color: #5abaf9;
}
#top-bar .tabs-wrapper .tab.edit-tab,
#top-bar .tabs-wrapper .tab.new-tab {
  min-width: 100px;
  width: 100px;
  max-width: 100px;
  padding: 0px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
#top-bar .tabs-wrapper .tab.new-tab {
  min-width: 40px;
  width: 40px;
  max-width: 40px;
}
#top-bar .tabs-wrapper .tab .delete-tab {
  outline: solid 0px red;
  padding: 0px 10px 0px 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#top-bar .tabs-wrapper .tab .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#top-bar .tabs-wrapper .tab-navigation {
  position: absolute;
  right: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  width: 300px;
  padding-right: 15px;
  justify-content: flex-start;
  background-color: #242424;
  min-width: 15px;
  min-height: 15px;
}
#top-bar .tabs-wrapper .tab-navigation .arrow {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#top-bar .tabs-wrapper .tab-navigation .arrow.disabled {
  color: #4e4c4c;
  cursor: unset;
}
#top-bar .tabs-wrapper .tab-navigation .preset-name {
  overflow: hidden;
  flex-grow: 1;
  max-width: 220px;
  text-align: center;
  text-overflow: ellipsis;
}
.customstack-wrapper {
  box-sizing: border-box;
  flex-direction: column;
  padding: 10px;
  padding-right: 0;
  height: 100%;
  max-width: 980px;
  width: 100%;
}
.customstack-wrapper .btn-revert {
  margin: 1rem auto 0;
  width: 50%;
  background-color: #E93B3B;
  color: #fff;
}
.customstack-wrapper .btn-revert:disabled {
  background-color: #5a5a5a;
  cursor: not-allowed;
  color: #8a8a8a;
}
.customstack-wrapper.customstack-list {
  max-width: 1085px;
}
.customstack-wrapper.drag .draggable-item {
  transition: top 0.2s cubic-bezier(0.2, 0, 0, 1), left 0.2s cubic-bezier(0.2, 0, 0, 1);
}
.customstack-wrapper.drag .joystick {
  pointer-events: none;
}
.customstack-wrapper .droppable-root {
  min-height: calc(100vh - 90px);
  overflow: hidden;
}
.customstack-wrapper svg {
  cursor: pointer;
}
.customstack-wrapper .navigation-list {
  width: calc(100% - 5px);
  height: fit-content;
  display: flex;
}
.customstack-wrapper .navigation-list-panels {
  position: relative;
  width: 100%;
}
.customstack-wrapper .navigation-list-panels .hidden {
  width: 100%;
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  top: 0;
  max-height: calc(100vh - 85px);
}
.customstack-wrapper .navigation-list .action-button {
  background-color: #222;
  color: #d3d3d3;
  padding: 5px;
  margin-left: 0;
}
.customstack-wrapper .draggable-item.label {
  display: flex;
  justify-content: center;
  margin: 30px 0px 20px 0px;
  font-weight: bold;
  font-size: 18px;
}
.customstack-wrapper .draggable-item.vector-dials {
  margin: 30px 5px 20px 5px;
  display: flex;
  justify-content: center;
  min-height: 315px;
}
.customstack-wrapper button {
  cursor: pointer;
  background: transparent;
  border: 0px;
  color: #cdcccc;
  margin: 0px 7px;
}
.customstack-wrapper button:hover {
  color: #fff;
}
.customstack-wrapper button:focus {
  outline: none;
}
.customstack-wrapper .navigation-btn {
  height: 35px;
  margin: 0 10px 0 0;
  background-color: #222;
  color: #fff;
}
.customstack-wrapper .navigation {
  min-width: 150px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.customstack-wrapper .navigation .navigation-row {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
}
.customstack-wrapper .navigation .navigation-row button {
  width: 150px;
}
.customstack-wrapper .navigation .navigation-row p {
  margin: 0 5px;
  max-height: 35px;
  overflow: hidden;
  text-align: start;
}
.customstack-wrapper .navigation .navigation-row .navigation-icon {
  width: 15px;
  height: 15px;
}
.customstack-wrapper .navigation .navigation-row input:checked + .slider {
  background-color: #5abaf9;
  width: 60px;
}
.customstack-wrapper .custom-sliders {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.customstack-wrapper .custom-sliders .expand-icon {
  padding: 0;
  margin: 0;
  margin-right: 1rem;
}
.customstack-wrapper .custom-sliders .reset-sliders {
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
}
.customstack-wrapper .joystick-row {
  margin: 10px 0 0 0;
  justify-content: flex-start;
}
.customstack-wrapper .joystick-row .value-info {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-right: 15px;
}
.customstack-wrapper .joystick-row .value-info .axis-title {
  position: absolute;
  left: 5px;
  width: 40px;
  text-align: start;
  padding-left: 8px;
}
.customstack-wrapper .joystick-row .value-info .axis-title::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  height: 18px;
  width: 4px;
  border-radius: 20px;
  background-color: transparent;
}
.customstack-wrapper .joystick-row .value-info .axis-title.X-axis::before,
.customstack-wrapper .joystick-row .value-info .axis-title.Roll-axis::before {
  background-color: #E93B3B;
}
.customstack-wrapper .joystick-row .value-info .axis-title.Y-axis::before,
.customstack-wrapper .joystick-row .value-info .axis-title.Pitch-axis::before {
  background-color: #88BD49;
}
.customstack-wrapper .joystick-row .value-info .axis-title.Z-axis::before,
.customstack-wrapper .joystick-row .value-info .axis-title.Yaw-axis::before {
  background-color: #0074D0;
}
.customstack-wrapper .joystick-row .value-info input {
  width: 155px;
  padding: 0px 10px 0px 50px;
  cursor: ew-resize;
}
.customstack-wrapper .joystick-row .control-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 auto;
}
.customstack-wrapper .joystick-row .lock-image {
  width: 25px;
  height: 25px;
  margin: 0 10px 0 0;
}
.customstack-wrapper .joystick-row .gamepad {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
}
.customstack-wrapper .joystick-row .gamepad.selected {
  color: #0074D0;
}
.customstack-wrapper .image-dropdown-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  overflow: auto;
}
.customstack-wrapper .image-dropdown-row .dropdown-image {
  box-sizing: border-box;
  margin: 0 30px 0 0;
  border: solid 2px rgba(0, 0, 0, 0);
  border-radius: 10px;
}
.customstack-wrapper .image-dropdown-row .selected-dropdown-image {
  border: solid 2px #5abaf9;
}
.droppable.droppable-panel {
  overflow: hidden;
  position: relative;
  background-color: #202020;
  min-height: 200px;
  padding: 10px 0px;
  margin-right: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
}
.droppable.droppable-panel > .title {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  color: #FFF;
  padding: 10px;
  background-color: #171717;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.droppable.has-title {
  padding: 0px 0px 10px 0px;
}
.droppable .template-property-select {
  background-color: #222;
  border: solid 1px #000;
  color: #b5b5b5;
  padding: 4px;
  border-radius: 5px;
  min-width: 100px;
}
.droppable.dragging {
  border: 1px solid #5abaf9;
}
.droppable.droppable-list {
  width: calc(100% - 5px);
  min-height: 200px;
  padding: 0.5rem 0;
}
.droppable.droppable-list.hidden {
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  top: 0;
}
.droppable li {
  list-style: none;
}
.droppable .draggable-item {
  position: relative;
  margin: 10px 1px 0;
  top: 0;
  left: 0;
}
.droppable .draggable-item.selected {
  outline: 1px solid #5abaf9;
  background-color: rgba(90, 186, 249, 0.3);
}
.droppable .draggable-item .navigation-row {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
}
.droppable .draggable-item .navigation-row button {
  width: 150px;
}
.droppable .draggable-item .navigation-row p {
  margin: 0 5px;
  max-height: 35px;
  overflow: hidden;
  text-align: start;
}
.droppable .draggable-item .navigation-row .navigation-icon {
  width: 15px;
  height: 15px;
}
.droppable .draggable-item .navigation-row input:checked + .slider {
  background-color: #5abaf9;
  width: 60px;
}
.droppable .draggable-item .navigation-row button {
  border: solid 1px #202020;
  border-radius: 5px;
  cursor: pointer;
}
.droppable .draggable-item .navigation-row .navigation-btn {
  height: 35px;
  margin: 0 10px 0 0;
  background-color: #222;
  color: #fff;
}
.droppable .draggable-item .navigation-row .navigation-btn.selected {
  background-color: #5abaf9;
}
.droppable .draggable-item .drag-handle {
  display: none;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}
.droppable .draggable-item .drag-handle svg {
  font-size: 14px;
}
.droppable .draggable-item .drag-handle.right {
  left: 100%;
}
.droppable .draggable-item.edit {
  padding-left: 15px;
}
.droppable .draggable-item.edit > .drag-handle {
  display: block;
}
.droppable .draggable-item.dragging {
  visibility: visible;
}
.droppable .draggable-item.dragging:after {
  content: '';
  position: absolute;
  display: block;
  border: 1px solid #5abaf9;
  border-radius: 10px;
  background-color: #202020;
  z-index: -1;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  left: -5px;
  top: -3px;
}
.unbound-property {
  display: flex;
  align-items: center;
  justify-content: center;
}
.unbound-property svg {
  position: relative !important;
  margin: 0 5px 0 0;
  color: #e2ee2d;
  cursor: default;
}
.widget-button-wrapper {
  height: 0px;
  width: 100%;
  overflow: hidden;
  padding-top: 591.44px / 1127.34px * 100%;
  position: relative;
}
.widget-button {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin: 10px;
  border: 1px solid #3d3f48;
  border-radius: 5px;
  box-shadow: 0 1px 0 rgba(76, 76, 76, 0.5) inset, 0 -1px 0 rgba(255, 255, 255, 0.1) inset, 0 4px 0 #0a0a0a, 0 4px 2px rgba(0, 0, 0, 0.5);
  background: #181818;
  font-family: 'Oswald', Helvetica;
  line-height: 43px;
  color: #FFF;
  text-decoration: none;
  text-shadow: -1px -1px 0 #3d3f48;
  transition: initial;
  cursor: pointer;
}
.widget-button:focus {
  outline: none;
}
.widget-button:active {
  top: -16px !important;
  box-shadow: 0 4px 9px 2px rgba(22, 22, 22, 0.5) inset, 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
  color: #CECECE;
}
.dial-wrapper-container {
  text-align: center;
}
.dial-wrapper-block {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.dial-widget-wrapper {
  width: 295px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dial-widget-wrapper .dial-label {
  margin: 0 0 10px;
  text-align: center;
}
.dial-widget-wrapper > svg {
  position: absolute;
  top: 2px;
  right: 16px;
}
.dial-widget-wrapper .expand-icon {
  left: 16px;
}
.dial-border-first {
  width: 226px;
}
.controls-block .dial-border-first {
  width: 213px;
}
.dial-border-first,
.dial-border-second {
  padding: 10px;
  border: 1px solid #2c2c2c;
  border-radius: 50%;
}
.dial-border-first {
  background: #202020;
}
.dial-border-second {
  background: #161616;
}
.dial-wrapper {
  touch-action: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border: 0px;
  flex-wrap: wrap;
  animation: colors 1s infinite;
}
.dial-wrapper:focus {
  outline: none;
}
.dial-wrapper:before {
  content: "";
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.dial-circle {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #2c2c2c;
  border-radius: 50%;
  background-color: #202020;
  animation: colors 1s infinite;
}
.dial-circle .svg-circle {
  stroke: url(#shape-gradient) #fff;
}
.dial-circle .svg-circle.range {
  stroke: #0067B4;
}
.dial-circle .dial-ticks .dial-tick:after {
  transition: initial;
}
.svg-circle {
  position: absolute;
  width: 100%;
  height: 100%;
}
.dial-line {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100%;
  transform-origin: center center;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dial-line::after {
  content: '';
  width: 3px;
  height: 8%;
  margin-top: 46%;
  border-radius: 0 0 20px 20px;
  background-color: #0067b4;
}
.dial-center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55%;
  height: 55%;
  border: 1px solid #2c2c2c;
  border-radius: 50%;
  background: #161616;
}
.dial-center-wrapper .dial-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  height: 70%;
  border: none;
  border-radius: 50%;
  background-color: #202020;
  box-shadow: 0px 0px 10px 2px #00000066;
  font-weight: bold;
  user-select: none;
}
.dial-center-wrapper .dial-center .dial-label {
  display: none;
  color: #FFF;
  margin-bottom: 5px;
}
.dial-center-wrapper .dial-center .input-field {
  width: 70px;
  border: 1px solid #2c2c2c;
  color: #ffffffa6;
}
.dial-value {
  z-index: 9;
}
.dial-tick {
  position: absolute;
  width: 40%;
  height: 40%;
  top: 30%;
  left: 30%;
  z-index: 5;
  overflow: visible;
  outline: solid 0px purple;
  display: flex;
  justify-content: center;
}
.dial-tick::after {
  content: "";
  position: absolute;
  top: 5px;
  width: 2px;
  height: 10px;
  transition: all 180ms ease-out;
  filter: blur(0.5px);
}
.dial-tick.large::after {
  height: 40%;
  top: -65%;
  background: #ffffffa6;
}
.dial-tick.short::after {
  height: 40%;
  top: -65%;
  background: #ffffff12;
}
.joystick-outer-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.joystick-outer-wrapper .reset-btn {
  position: absolute;
  right: 22px;
  top: 5px;
}
.joystick-outer-wrapper .controls-block {
  width: 100%;
}
.joystick-outer-wrapper .controls-block .inputs {
  display: flex;
  justify-content: center;
}
.joystick-outer-wrapper .controls-block .inputs .control {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px 0 0;
}
.joystick-outer-wrapper .controls-block .inputs .control .label {
  margin: 0 10px;
}
.joystick-outer-wrapper .controls-block .slider .slider-wrapper {
  margin-top: 0.5rem;
  width: 100%;
}
.joystick-outer-wrapper .controls-block .slider .centered {
  text-align: center;
}
.joystick-outer-wrapper .controls-block .slider .values {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}
.joystick-outer-wrapper .controls-block > .label {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 15px;
}
.joystick-wrapper {
  width: 205px;
  height: 230px;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.joystick-wrapper .idle {
  transition: all 0.2s ease-in-out;
}
.joystick-wrapper .joystick-value-wrapper .joystick-value-label {
  outline: solid 0px red;
  width: 80px;
  margin: 5px auto 0px;
  text-align: center;
  display: none;
}
.joystick-wrapper .joystick-value-wrapper .joystick-value {
  width: fit-content;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  margin: 10px auto 0px;
  min-width: 80px;
  max-width: 185px;
}
.joystick-wrapper .joystick-value-wrapper .joystick-value span {
  width: fit-content;
  margin: 0px 5px;
}
.joystick-wrapper .joystick {
  touch-action: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: calc(75% - (25px * 0.75));
  position: relative;
  background-color: #161616;
  border-radius: 50%;
  margin: 0 auto;
  border: solid 2px #2d2d2d;
}
.joystick-wrapper .joystick::before {
  content: "";
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
}
.joystick-wrapper .joystick::after {
  content: "";
  background: #1A1A1A;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  transform: scale(0.48);
  border: solid 2px #2e2e2e;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 10px 0px;
}
.joystick-wrapper .joystick .arrows {
  position: absolute;
  width: 100%;
  height: 100%;
}
.joystick-wrapper .joystick .arrows svg {
  position: absolute;
  font-size: 40px;
  color: #606060;
  min-width: 40px;
}
.joystick-wrapper .joystick .arrows svg.fa-caret-up,
.joystick-wrapper .joystick .arrows svg.fa-caret-down {
  left: 50%;
  transform: translateX(-50%);
}
.joystick-wrapper .joystick .arrows svg.fa-caret-left,
.joystick-wrapper .joystick .arrows svg.fa-caret-right {
  top: 50%;
  transform: translateY(-50%);
}
.joystick-wrapper .joystick .arrows svg.fa-caret-right {
  right: 0;
}
.joystick-wrapper .joystick .arrows svg.fa-caret-down {
  bottom: 0;
}
.joystick-wrapper .joystick .circle-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}
.joystick-wrapper .joystick .circle-wrapper .circle {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #606060;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 6px 0px;
  z-index: 2;
  border: solid 2px #7d7d7d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.joystick-wrapper .joystick .circle-wrapper .circle.animate {
  transition: transform 0.2s ease-out;
}
.joystick-wrapper .joystick .circle-wrapper .circle .label {
  font-size: 25px;
  color: #393939;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0px;
  font-weight: bolder;
}
.joystick-wrapper .joystick .label {
  position: absolute;
  overflow: hidden;
}
.joystick-wrapper-block {
  width: 100%;
  display: flex;
  justify-content: center;
}
.step-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.step-wrapper .label-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 110px 0 30px;
}
.step-wrapper .label-wrapper :first-child {
  padding: 0 20px 0 0;
}
.joystick-group .slider-wrapper {
  width: 100%;
}
.joystick-outer-wrapper .slider-wrapper,
.joystick-group .slider-wrapper {
  width: 100%;
}
.custom-sliders .slider-row .title,
.controls-block .slider-row .title,
.custom-sliders .control .title,
.controls-block .control .title,
.custom-sliders .slider-row .label,
.controls-block .slider-row .label,
.custom-sliders .control .label,
.controls-block .control .label {
  position: relative;
  display: flex;
  align-items: center;
  overflow: inherit;
}
.custom-sliders .slider-row .input-field-container,
.controls-block .slider-row .input-field-container,
.custom-sliders .control .input-field-container,
.controls-block .control .input-field-container {
  position: relative;
}
.custom-sliders .slider-row .input-field-container::after,
.controls-block .slider-row .input-field-container::after,
.custom-sliders .control .input-field-container::after,
.controls-block .control .input-field-container::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 3px;
  height: 18px;
  width: 4px;
  border-radius: 20px;
  background-color: transparent;
}
.custom-sliders .slider-row:first-child .input-field-container::after,
.controls-block .slider-row:first-child .input-field-container::after,
.custom-sliders .control:first-child .input-field-container::after,
.controls-block .control:first-child .input-field-container::after {
  background-color: #E93B3B;
}
.custom-sliders .slider-row:nth-child(2) .input-field-container::after,
.controls-block .slider-row:nth-child(2) .input-field-container::after,
.custom-sliders .control:nth-child(2) .input-field-container::after,
.controls-block .control:nth-child(2) .input-field-container::after {
  background-color: #88BD49;
}
.custom-sliders .slider-row:nth-child(3) .input-field-container::after,
.controls-block .slider-row:nth-child(3) .input-field-container::after,
.custom-sliders .control:nth-child(3) .input-field-container::after,
.controls-block .control:nth-child(3) .input-field-container::after {
  background-color: #0074D0;
}
.custom-sliders .slider-row:nth-child(4) .input-field-container::after,
.controls-block .slider-row:nth-child(4) .input-field-container::after,
.custom-sliders .control:nth-child(4) .input-field-container::after,
.controls-block .control:nth-child(4) .input-field-container::after {
  background-color: #cdd000;
}
.slider-wrapper {
  width: calc(100% - 60px);
  outline: solid 0px red;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  align-self: center;
  touch-action: none;
}
.slider-wrapper .input-field {
  margin: 0 0 0 10px;
}
.slider-wrapper .slider-inner {
  height: fit-content;
  width: calc(100% - 20px);
  outline: solid 0px green;
  position: relative;
  margin: 0 auto;
}
.slider-wrapper .slider-clickable {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slider-wrapper .slider-block {
  width: 100%;
  height: 8px;
  z-index: 2;
  position: relative;
}
.slider-wrapper .slider-block .slider {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background-color: #151515;
  border-radius: 3px;
}
.slider-wrapper .slider-block .circle-wrapper {
  pointer-events: none;
  width: 100%;
  position: relative;
  top: -23px;
  left: -10px;
}
.slider-wrapper .slider-block .circle-wrapper .circle {
  pointer-events: all;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  background: #0074D0;
  touch-action: none;
}
.slider-wrapper .slider-block .range-fill {
  width: 100%;
  padding: 0;
  height: 8px;
  background-color: #404040;
  border-radius: 3px;
  position: relative;
  top: -8px;
  pointer-events: none;
}
.slider-wrapper .grid-lines-wrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  padding-top: 20px;
  z-index: 1;
}
.slider-wrapper .grid-lines-wrapper .grid-lines {
  width: 0;
  height: 7px;
  border: 1px solid #151515;
  transition: all 0.2s ease;
}
.slider-wrapper .grid-lines-wrapper .active {
  border: solid 1px #404040;
}
.slider-wrapper .label-wrapper {
  display: none;
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 2px;
  pointer-events: none;
  margin-top: -5px;
}
.droppable-list.hidden .switch::after,
.droppable-list.hidden .switch::before {
  transition: none;
}
.toggle {
  position: relative;
  display: block;
  width: 200px;
  min-height: 140px;
}
.toggle .title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 10px;
  text-align: center;
  cursor: inherit;
  height: 20px;
  height: 33px;
}
.toggle .switch {
  overflow: hidden;
  position: relative;
  width: calc(100% - 30px);
  height: 74px;
  border: 2px solid #2c2c2c;
  border-radius: 5px;
  background: #181818;
  cursor: pointer;
  margin: 0px 12px;
}
.toggle .switch:before {
  content: 'ON';
  display: block;
  position: absolute;
  height: calc(100% - 10px);
  text-align: center;
  line-height: 70px;
  width: calc(50% - 5px);
  top: 5px;
  left: 50%;
  background: #181818;
  color: #9d9d9d;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle .switch:after {
  content: 'OFF';
  display: block;
  position: absolute;
  height: calc(100% - 10px);
  text-align: center;
  line-height: 70px;
  width: calc(50% - 5px);
  bottom: 5px;
  left: 5px;
  background: #fff4d3;
  background: #202020;
  color: #9d9d9d;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle.checked .switch:before {
  background: #0074d7;
  color: #FFF;
}
.toggle.checked .switch:after {
  background: #181818;
}
.customstack-wrapper.drawer-shown {
  height: calc(100% - 320px);
  overflow: auto;
}
.tabs-open .vector-drawer-wrapper {
  width: calc(100% - 400px);
}
@media only screen and (max-device-width: 1366px) {
  .tabs-open .vector-drawer-wrapper {
    width: calc(100% - 225px);
  }
}
.vector-drawer-wrapper {
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #242424;
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 99;
  padding: 20px;
  box-sizing: border-box;
  border-top: 1px solid #353944;
  border-left: 1px solid #353944;
}
.vector-drawer-wrapper .slider-clickable .slider-block {
  height: 8px;
  margin: 0;
}
.vector-drawer-wrapper .slider-clickable .slider-block .slider {
  height: inherit;
}
.vector-drawer-wrapper .slider-clickable .slider-block .range-fill {
  height: inherit;
}
.vector-drawer-wrapper .joystick-outer-wrapper .joystick .label {
  display: initial;
  margin: 0;
}
.vector-drawer-wrapper .controls-block {
  height: 100%;
  width: 100%;
}
.vector-drawer-wrapper .controls-block .sliders-component-wrapper .slider-row svg {
  display: none;
}
.vector-drawer-wrapper .drawer-header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 0 10px;
  box-sizing: border-box;
}
.vector-drawer-wrapper .drawer-header .header-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
}
.vector-drawer-wrapper .drawer-header .header-block > span {
  font-size: 24px;
}
.vector-drawer-wrapper .drawer-header .header-block .reset-btn {
  margin: 0 0 0 10px;
}
.vector-drawer-wrapper .drawer-header .btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.vector-drawer-wrapper .drawer-header .close-btn {
  margin-left: 10px;
  font-size: 18px;
}
.vector-drawer-wrapper .modes {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px 0 0;
}
.vector-drawer-wrapper .modes .mode {
  background-color: #555;
  padding: 8px;
  cursor: pointer;
  border: solid 1px #000;
}
.vector-drawer-wrapper .modes .mode:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.vector-drawer-wrapper .modes .mode:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.vector-drawer-wrapper .modes .mode.selected {
  background: #50b6f9;
}
@media only screen and (min-device-width: 1366px) {
  .vector-drawer-wrapper .modes {
    padding: 0;
  }
}
.vector-drawer-wrapper .lock-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0px;
  right: 40px;
}
.vector-drawer-wrapper .dial-widget-wrapper > .dial-label {
  display: none;
}
.vector-drawer-wrapper .dial-widget-wrapper .dial-center-wrapper .dial-label {
  display: block;
}
.sliders-component-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sliders-component-wrapper .title {
  min-width: 30px;
  width: 30px;
}
.tab-group .color-picker-container {
  width: 283px;
}
.color-picker-container .fa-undo,
.color-picker-container .expand-icon {
  margin-top: 10px;
}
.color-picker-container {
  height: 100%;
  display: flex;
  width: 295px;
  min-height: 115px;
  justify-content: center;
}
.color-picker-sliders {
  flex: 1;
}
.color-picker-sliders .slider-row {
  position: relative;
  min-height: 20px;
  width: 100%;
  border-radius: 12px;
  border: solid 2px #1E1C1E;
  margin-bottom: 1rem;
}
.color-picker-sliders .slider-row.red {
  background-image: linear-gradient(to right, #000000, #F00);
}
.color-picker-sliders .slider-row.green {
  background-image: linear-gradient(to right, #000000, #0F0);
}
.color-picker-sliders .slider-row.blue {
  background-image: linear-gradient(to right, #000000, #00F);
}
.color-picker-sliders .slider-row-pointer {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #423e49;
}
.color-picker-wheel {
  width: 250px;
  height: calc(100% - 10px);
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.color-picker-wheel .rectangle {
  position: relative;
  margin-top: 10px;
  width: 180px;
  height: 180px;
  border-radius: 12px;
  touch-action: none;
  cursor: pointer;
  background: -webkit-linear-gradient(top, rgba(230, 230, 230, 0) 0%, black 100%), -webkit-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
}
.color-picker-wheel .rectangle-area-pointer {
  position: absolute;
  width: 15px;
  height: 15px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 49%, #4c4c4c 50%, #727272 60%, #4c4c4c 70%);
  border: 2px solid #5d5d5d;
  top: 0;
  left: 0;
  z-index: 10;
  border-radius: 50%;
  cursor: pointer;
}
.color-picker-wheel .circle {
  position: relative;
  margin-top: 10px;
  width: 210px;
  min-height: 210px;
  border-radius: 50%;
  background-color: #1E1C1E;
  cursor: pointer;
}
.color-picker-wheel .circle .circle-gradient {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 195px;
  height: 195px;
  z-index: 5;
  background: conic-gradient(from -90deg, #850214, #867e13, #1a8b1d, #157987, #0a0087, #85007e, #850214);
  border-radius: 50%;
}
.color-picker-wheel .circle .circle-gradient .canvas-area {
  position: relative;
  width: 175px;
  height: 175px;
  background-color: rgba(30, 28, 30, 0.8);
  border-radius: 50%;
  touch-action: none;
}
.color-picker-wheel .circle .circle-gradient .canvas-area:after,
.color-picker-wheel .circle .circle-gradient .canvas-area::before {
  content: '';
  position: absolute;
  z-index: 1;
  background-color: #3c4039;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.color-picker-wheel .circle .circle-gradient .canvas-area::after {
  width: calc(100% - 1px);
  height: 1px;
}
.color-picker-wheel .circle .circle-gradient .canvas-area::before {
  width: 1px;
  height: calc(100% - 1px);
}
.color-picker-wheel .circle .circle-gradient .canvas-area-pointer {
  position: absolute;
  width: 15px;
  height: 15px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 49%, #4c4c4c 50%, #727272 60%, #4c4c4c 70%);
  border: 2px solid #5d5d5d;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  z-index: 10;
  border-radius: 50%;
  cursor: pointer;
}
.color-picker-wheel .color-picker-inputs {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.color-picker-wheel .color-picker-inputs .input {
  position: relative;
}
.color-picker-wheel .color-picker-inputs .input:not(:last-child) {
  margin-right: 1rem;
}
.color-picker-wheel .color-picker-inputs .input::after {
  content: '';
  display: block;
  bottom: -5px;
  position: absolute;
  height: 2px;
  width: 100%;
}
.color-picker-wheel .color-picker-inputs .input.red::after {
  background-color: #dd352f;
}
.color-picker-wheel .color-picker-inputs .input.green::after {
  background-color: #169b32;
}
.color-picker-wheel .color-picker-inputs .input.blue::after {
  background-color: #2262bc;
}
.color-picker-wheel .color-picker-inputs .input-field {
  width: 60px;
  height: 30px;
  border-radius: 0;
  border: 1px solid #1a1a1a;
  cursor: unset;
}
.color-picker-wheel .color-picker-inputs .color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.color-picker-wheel .color-picker-slider {
  touch-action: none;
  position: relative;
  border-radius: 10px;
  margin: 20px 0;
  padding: 5px 0;
  color: #8a8a8a;
  font-weight: 500;
  font-size: 15px;
}
.color-picker-wheel .color-picker-slider::after,
.color-picker-wheel .color-picker-slider::before {
  position: absolute;
  content: '';
  width: 2px;
  height: 10px;
  background-color: #3d3d3d;
  bottom: -12px;
  border-radius: 10px;
}
.color-picker-wheel .color-picker-slider::before {
  left: 1px;
}
.color-picker-wheel .color-picker-slider::after {
  right: 1px;
}
.color-picker-wheel .color-picker-slider-label {
  position: absolute;
  top: -25px;
  left: -10px;
}
.color-picker-wheel .color-picker-slider-value {
  position: absolute;
  right: -10px;
  top: -25px;
}
.color-picker-wheel .color-picker-slider-bgc {
  height: 5px;
  background-color: #000;
  cursor: pointer;
  position: relative;
}
.color-picker-wheel .color-picker-slider-pointer {
  position: absolute;
  z-index: 1;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: #848484;
  top: -2px;
  left: -7px;
  cursor: pointer;
}
.color-picker-wheel .color-picker-slider-pointer::after,
.color-picker-wheel .color-picker-slider-pointer::before {
  content: '';
  width: 6px;
  height: 12px;
  position: absolute;
  top: 3px;
}
.color-picker-wheel .color-picker-slider-pointer::before {
  border-bottom-left-radius: 9px;
  border-top-left-radius: 9px;
  background-color: #242424;
  left: 3px;
}
.color-picker-wheel .color-picker-slider-pointer::after {
  right: 3px;
  border-bottom-right-radius: 9px;
  border-top-right-radius: 9px;
  background-color: #848484;
}
.color-picker-wheel .slider-wheel-top {
  color: #8a8a8a;
  display: flex;
  justify-content: space-between;
}
.color-picker-slider-wheel {
  touch-action: none;
  position: relative;
  width: 210px;
  min-height: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #2e2e2e;
  overflow: hidden;
  cursor: pointer;
  box-shadow: inset 25px 0px 25px -25px rgba(0, 0, 0, 0.8), inset -25px 0px 25px -25px rgba(0, 0, 0, 0.8);
  background-color: #202020;
}
.color-picker-slider-wheel:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(89deg, #050505 0%, #23232300 19%, #1B1B1B00 81%, #080808 100%) 0% 0% no-repeat padding-box;
}
.color-picker-slider-wheel .circles-list {
  position: absolute;
  left: -50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.color-picker-slider-wheel .circles-list .slider-circle {
  width: 7px;
  height: 7px;
  background: #464646;
  border-radius: 7px;
  border: solid 1px #707070;
  margin-right: 3px;
}
.color-pickers-list-container {
  display: flex;
  width: 100%;
}
.color-pickers-list-container .color-picker-container {
  height: 448px;
}
.color-pickers-list-container .colors-list {
  margin: 0;
  padding: 0;
  flex: 1;
  max-height: 450px;
  overflow: auto;
}
.color-pickers-list-container .colors-list .list-item {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 1rem;
  position: relative;
  cursor: pointer;
}
.color-pickers-list-container .colors-list .list-item .draggable-item {
  width: calc(100% - 20px);
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.color-pickers-list-container .colors-list .list-item .draggable-item .expand-icon {
  margin: 0 1rem;
}
.color-pickers-list-container .colors-list .list-item .color-preview {
  width: 50px;
  height: 15px;
  border-radius: 5px;
  margin-right: 1rem;
}
.color-pickers-list-container .colors-list .list-item.selected::before {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  width: 4px;
  height: 100%;
  background-color: #5abaf9;
}
.widget-text-wrapper {
  flex-grow: 1;
}
.widget-text-wrapper .widget-text {
  width: 100%;
}
.buttons-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.buttons-wrapper button {
  border: solid 1px #000;
  border-left: 0px;
  font-size: 14px;
  color: #FFF;
  background-color: #242730;
  padding: 5px 10px;
}
.buttons-wrapper button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: solid 1px #000;
}
.buttons-wrapper button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.buttons-wrapper button:focus {
  outline: none;
}
.buttons-wrapper button.selected {
  background-color: #5abaf9;
  color: #fff;
  border-color: #000;
}
.input-field-container::before {
  content: "";
  position: absolute;
  z-index: 1;
  width: 10px;
  height: 100%;
}
.input-field {
  width: 120px;
  height: 25px;
  font-size: 15px;
  text-align: center;
  border-radius: 5px;
  background-color: #191919;
  border: solid 1px #000;
  touch-action: none;
  cursor: ew-resize;
}
.snapshot-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 20px;
  outline: none;
  background-color: #202020;
  margin: 20px;
  height: calc(100% - 45px);
  max-width: 980px;
  width: calc(100% - 80px);
}
.snapshot-wrapper .list-wrapper {
  height: calc(100% - 210px);
  border-bottom: solid 1px #000;
  padding: 20px;
}
.snapshot-wrapper .search-block {
  width: 100%;
  height: 35x;
  display: flex;
  align-content: center;
}
.snapshot-wrapper .search-block .snapshot-btn {
  width: 25%;
  height: 35px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5abaf9;
  color: #FFF;
}
.snapshot-wrapper .search-field {
  width: calc(70% - 100px);
  height: 23px;
  display: flex;
  align-items: center;
  margin: 0 50px;
  padding: 5px 10px;
  border-radius: 5px;
  border: solid 1px #555;
  cursor: pointer;
}
.snapshot-wrapper .search-field svg {
  width: 15px;
  height: 15px;
  margin: 0 15px 0 0;
  color: #5abaf9;
  cursor: pointer;
}
.snapshot-wrapper .search-field input {
  width: 100%;
  height: 20px;
  background-color: initial;
  border: none;
  cursor: pointer;
}
.snapshot-wrapper table,
.snapshot-wrapper th,
.snapshot-wrapper td {
  border-collapse: collapse;
}
.snapshot-wrapper tr {
  height: 45px;
  border-top: 1px solid #535252;
  cursor: pointer;
}
.snapshot-wrapper tr:first-child {
  border-top: none;
}
.snapshot-wrapper tr:last-child {
  border-bottom: 1px solid #868686;
}
.snapshot-wrapper .selected-row {
  background-color: #335;
}
.snapshot-wrapper td {
  padding: 10px;
  font-size: 0.8em;
  line-height: 18px;
  overflow: hidden;
  text-align: start;
}
.snapshot-wrapper td .name-block {
  display: flex;
  align-items: center;
}
.snapshot-wrapper td img {
  max-height: 38px;
  max-width: 38px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 10px 0 5px;
}
.snapshot-wrapper td p {
  max-height: 35px;
  word-break: break-all;
  overflow: hidden;
  text-align: start;
}
.snapshot-wrapper td .play-icon {
  display: flex;
  justify-content: center;
  border: 1px solid #FFF;
  padding: 5px 0;
  transition: background-color 0.3s;
  cursor: pointer;
}
.snapshot-wrapper td .play-icon svg {
  transition: color 0.3s;
}
.snapshot-wrapper td .play-icon:hover {
  background-color: #fff;
  color: #202020;
}
.snapshot-wrapper th {
  height: 40px;
  text-align: start;
  padding: 0 15px;
  position: sticky;
  top: -2px;
  background: #202020;
}
.snapshot-wrapper thead .name {
  padding: 0 0 0 55px;
}
.snapshot-wrapper thead .created-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.snapshot-wrapper thead .created-header svg {
  width: 15px;
  height: 15px;
  color: #5abaf9;
}
.snapshot-wrapper thead tr {
  cursor: initial;
}
.snapshot-wrapper .table-wrapper {
  width: 100%;
  max-height: calc(100% - 20px);
  overflow-y: auto;
}
.snapshot-wrapper table {
  width: calc(100% - 5px);
  table-layout: fixed;
  margin: 10px 0 0 0;
}
.snapshot-wrapper table .name {
  width: 40%;
  padding: 0 0 0 50px;
  cursor: pointer;
}
.snapshot-wrapper table .name .name-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.snapshot-wrapper table .name .box {
  max-height: 38px;
  max-width: 38px;
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin: 0 10px 0 5px;
}
.snapshot-wrapper table .description {
  width: 30%;
  cursor: pointer;
}
.snapshot-wrapper table .created {
  width: 30%;
  cursor: pointer;
}
.snapshot-wrapper table .sort-icon {
  margin-left: 0.5rem;
}
.snapshot-wrapper .controls-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 20px;
  position: absolute;
  bottom: 10px;
  left: 0px;
}
.snapshot-wrapper .snapshot-btn {
  width: 30%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bolder;
  margin: 0 30px;
  cursor: pointer;
  background-color: #5abaf9;
  color: #FFF;
}
.snapshot-wrapper .snapshot-btn p {
  max-height: 30px;
  overflow: hidden;
}
.snapshot-wrapper .snapshot-preview-wrapper {
  height: 25%;
  padding: 20px;
  padding-top: 0px;
  position: relative;
}
.snapshot-wrapper .snapshot-preview-wrapper .hint-text {
  color: #4b4b4b;
}
.snapshot-wrapper .snapshot-preview-wrapper .preview-title {
  display: flex;
  justify-content: space-between;
  font-size: 25px;
  margin: 20px 0 0 0;
}
.snapshot-wrapper .snapshot-preview-wrapper .preview-title svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.snapshot-wrapper .snapshot-preview-wrapper .description-title {
  width: 100%;
  font-size: 16px;
  font-weight: bolder;
  margin: 10px 0;
}
.snapshot-wrapper .snapshot-preview-wrapper .preview-image {
  width: 30vw;
  height: 20vw;
  margin: 30px auto;
  background-color: #ee34bf;
}
.snapshot-wrapper .snapshot-preview-wrapper .preview-description {
  width: 100%;
  margin: 30px 0;
}
.snapshot-wrapper .snapshot-preview-wrapper .controls-block {
  justify-content: space-between;
}
.snapshot-wrapper .snapshot-preview-wrapper .controls-block .snapshot-btn {
  width: 50%;
}
.snapshot-wrapper .snapshot-preview-wrapper .controls-block .snapshot-dropdown {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.snapshot-wrapper .snapshot-preview-wrapper .controls-block .snapshot-dropdown .title {
  margin: 0 10px 0 0;
}
.snapshot-wrapper .snapshot-preview-wrapper .controls-block .snapshot-dropdown .dropdown {
  width: 80%;
  height: 30px;
  color: #FFF;
  background-color: #222;
  border-radius: 5px;
  outline: none;
  padding: 0 10px;
  cursor: pointer;
}
.save-asset-modal {
  width: 500px;
  height: 290px;
  background-color: #242424;
  border: solid 1px #464646;
  border-radius: 5px;
  margin: auto;
  padding: 15px;
}
.save-asset-modal .save-row {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.save-asset-modal .save-row svg {
  cursor: pointer;
}
.save-asset-modal .item-title {
  width: 100px;
  text-align: end;
  padding: 10px;
}
.save-asset-modal .item-content {
  width: calc(100% - 100px);
  border-radius: 5px;
  background-color: #353944;
  border: none;
  outline: none;
  color: #FFFFFF;
  padding: 10px;
  cursor: text;
}
.save-asset-modal .item-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
}
.save-asset-modal .path {
  padding: 10px;
  background-color: inherit;
}
.save-asset-modal .snapshot-btn {
  width: 50%;
  height: 40px;
  text-transform: uppercase;
  font-weight: bolder;
  margin: 10px 0 0 50%;
  background-color: #5abaf9;
  cursor: pointer;
}
.snapshot-btn {
  position: relative;
}
.snapshot-btn .spin-icon {
  position: absolute;
  right: 20px;
}
.sequencer-wrapper .list-wrapper {
  height: 100%;
  border-bottom: 0;
}
.sequencer-wrapper .search-block .search-field {
  width: 100%;
}
.sequencer-wrapper .table-wrapper {
  max-height: calc(100% - 70px);
}
.sequencer-wrapper .table-wrapper td {
  padding: 0 15px;
  cursor: default;
}
.sequencer-wrapper .table-wrapper .col {
  flex-grow: 1;
}
.sequencer-wrapper .table-wrapper .col-4 {
  width: 50px;
}
.droppable .draggable-item.tab-group {
  margin: 10px 15px;
}
.tab-group {
  border: solid 2px #171717;
  border-radius: 5px;
}
.tab-widget-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
}
.tab-widget-wrapper .btn {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #171717;
  text-transform: uppercase;
  margin: 0px;
  border-radius: 0px;
  border: 0px;
  border-top: solid 0px #161616;
  border-right: solid 1px #202020;
}
.tab-widget-wrapper .btn:last-child {
  border-right: 0px;
}
.tab-widget-wrapper .btn.selected {
  background-color: #202020;
  color: #5abaf9;
}
.tab-widget-wrapper .btn.selected:hover {
  background-color: inherit;
}
.slider-row {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 18px);
  min-height: 40px;
  padding: 0px 35px 0 0;
}
.slider-row .title {
  width: 110px;
  min-width: 110px;
  margin: 10px;
}
.slider-row .limits {
  width: 70px;
  margin: 5px;
  text-align: center;
}
.slider-row .color {
  width: 100%;
  height: 30px;
  margin: 7px 7px 7px -10px;
  border-radius: 4px;
}
.slider-row svg.expand-icon {
  margin-right: 1rem;
}
.slider-row svg[data-icon="undo"]:not(.expand-icon) {
  position: absolute;
  right: 0;
}
.slider-row svg:not(.drag-icon) {
  padding: 5px;
}
.text-row .widget-text-wrapper {
  padding: 0;
}
.text-row .widget-text-wrapper .widget-text {
  height: 30px;
}
.actor-color {
  margin: 10px;
  display: flex;
}
.actor-color .color {
  min-width: 30px;
  height: 24px;
  margin: 0px 0px 0px 10px;
  border-radius: 4px;
  border: solid 2px #FFF;
}
.actor-color svg {
  margin: auto;
}
.actor-color .selected {
  color: #FFF;
  background-color: #5abaf9;
}
.actor-color .btn-title span {
  max-height: 30px;
  overflow: hidden;
}
.actor-color .btn-title,
.actor-color .btn {
  width: 170px;
  height: 45px;
  margin: 0 10px;
  padding: 10px 8px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #000;
  background-color: #222;
  color: inherit;
}
.actor-color .btn-title span,
.actor-color .btn span {
  flex: 1;
}
.actor-color .btn-title p,
.actor-color .btn p {
  max-height: 30px;
  overflow: hidden;
  text-align: start;
}
.actor-color button {
  outline: none;
}
.multiline-widget-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.multiline-widget-wrapper .btn-wrapper {
  width: 200px;
  height: 48px;
  margin: 5x;
  border: 1px solid transparent;
}
.multiline-widget-wrapper .btn-wrapper .widget-button-wrapper {
  width: 100%;
  overflow: initial;
}
.multiline-widget-wrapper .btn-wrapper .widget-button-wrapper .widget-button {
  height: 40px;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.multiline-widget-wrapper .btn-wrapper .widget-button-wrapper .widget-button:active {
  top: 4px !important;
}
.slider-row .title,
.toggle-row .title,
.dropdown-row .title {
  width: 130px;
  min-width: 130px;
  margin: 0 10px;
  max-height: 34px;
  overflow: hidden;
  text-align: start;
  font-size: 14px;
  line-height: 16px;
}
.modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #cdcccc;
  font-size: 14px;
  width: auto;
  margin: 0px;
  padding-bottom: 24px;
  margin-bottom: 50px;
  width: 520px;
  background-color: #242424;
  outline: none;
}
.rename-modal {
  background-color: #242424;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: -200px;
}
.rename-modal .btn {
  width: 100px;
  height: 40px;
}
.rename-modal input {
  font-size: 30px;
  text-align: center;
  background: none;
  outline: none;
  border-bottom: 2px solid white;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
.fullscreen.precision {
  z-index: 990;
}
.precision-modal {
  padding-bottom: 1rem;
  width: 800px;
  min-height: 500px;
  background-color: #242424;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.precision-modal .main {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.precision-modal .main.color-picker {
  width: 100%;
  justify-content: flex-start;
  margin-top: 1rem;
}
.precision-modal .main.color-picker .btn-group {
  display: flex;
  margin-left: auto;
  margin-right: 1rem;
}
.precision-modal .main.color-picker .btn-group .btn {
  padding: 8px 10px;
}
.precision-modal .main.color-picker .color-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.precision-modal .main.color-picker .color-picker-container {
  height: initial;
}
.precision-modal .color-picker-slider-wheel {
  min-height: 40px;
}
.precision-modal .color-picker-slider-wheel .slider-circle {
  width: 14px;
  height: 14px;
}
.precision-modal .slider-wheel-rows {
  flex: 1;
  margin-left: 1rem;
}
.precision-modal .slider-wheel-rows .slider-wheel-row {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.precision-modal .slider-wheel-rows .slider-wheel-row .precision-icon {
  font-size: 30px;
}
.precision-modal .slider-wheel-rows .slider-wheel-row .color-picker-slider-wheel {
  margin: 0;
  width: 70%;
  height: 20px;
}
.precision-modal .slider-wheel-rows .slider-wheel-row .input-field {
  width: 80px;
  margin: 0 1rem;
}
.precision-modal .slider-wheel-rows .slider-label {
  display: flex;
  width: 50px;
  height: 25px;
  align-items: center;
  justify-content: center;
  background-color: #171717;
}
.precision-modal .close-modal {
  z-index: 1;
  top: 11px;
}
.precision-modal .label {
  font-size: 18px;
  width: 100%;
  top: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #171717;
}
.precision-modal .label p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 770px;
  margin: 0;
  padding-right: 40px;
  padding-left: 15px;
  text-align: center;
}
.precision-modal .precision-icon {
  font-size: 40px;
  cursor: pointer;
  margin: 0 1rem;
}
.precision-modal .step-label {
  text-transform: uppercase;
  text-align: center;
}
.precision-modal .sensitivity-block {
  background: #1f1f1f;
  padding: 1rem;
  margin-top: 3rem;
}
.precision-modal .row {
  margin: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}
.precision-modal .row.top .slider-wrapper {
  margin: 0 2rem;
}
.precision-modal .row.top .input-field {
  width: 77px;
}
.precision-modal .row.m-0 {
  margin: 0;
}
.precision-modal .row .color-picker-slider-wheel {
  width: 100%;
}
.precision-modal .row .limits {
  font-size: 14px;
  margin: 0 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.precision-modal .row .slider-wrapper {
  width: 100%;
}
.precision-modal .row .slider-wrapper .slider-inner {
  margin: 0;
  width: 100%;
}
.precision-modal .row.space {
  justify-content: space-between;
}
.precision-modal .row.space .limits .input-field {
  width: 77px;
  margin-top: 5px;
}
.precision-modal .row.space .step-input {
  display: flex;
  align-items: center;
  margin-top: 21px;
}
.precision-modal .row.space .step-input .icon {
  font-size: 25px;
  margin: 0 1rem;
}
.precision-modal .row.start {
  align-items: flex-start;
}
.precision-modal .sensitivity-slider {
  width: calc(100% - 35px);
  margin: 3rem auto 0;
}
.precision-modal .toggle-checkbox {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.precision-modal .toggle-checkbox .toggle-label {
  margin-left: 0.5rem;
}
.alert-modal {
  background-color: #242424;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: -200px;
  min-width: 30%;
}
.alert-modal .alert-text {
  width: fit-content;
  font-size: 1.5vw;
  margin: 20px;
}
.alert-modal .btn {
  width: 100px;
  height: 40px;
  margin: 5px;
}
.icon-modal {
  height: 70%;
  width: 70%;
  background-color: #242424;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.icon-modal .search-position {
  top: 5%;
}
.icon-modal .icon-search {
  font-style: large;
}
.icon-modal .icon-wrapper {
  width: 90%;
  height: 65%;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10%;
}
.icon-modal .icon-wrapper .icon-block {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 70px;
  height: 90px;
  box-sizing: border-box;
  margin: 10px;
}
.icon-modal .icon-wrapper .icon-block .icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.icon-modal .icon-wrapper .icon-block .icon-text {
  padding: 0 3px;
  font-size: 10px;
  height: 28px;
  text-align: center;
  overflow: hidden;
}
.icon-modal .icon-wrapper .selected {
  background-color: #5abaf9;
  border-radius: 4px;
  color: #FFF;
}
.icon-modal .name-text {
  margin-top: 20px;
}
.icon-modal .btn {
  width: 200px;
  margin: 20px 20px 0px 20px;
}
.icon-modal input {
  font-size: 30px;
  text-align: center;
  background: none;
  outline: none;
  border-bottom: 2px solid white;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
.close-modal {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  cursor: pointer;
}
.list {
  border-right: solid 1px #282b34;
  height: 100%;
  overflow-y: auto;
}
.list .list-title {
  background-color: #303030;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: -1px;
  cursor: pointer;
  z-index: 10;
}
.list > ul > li > ul {
  overflow: hidden;
  max-height: 1000px;
  transition: all 0.2s ease;
  cursor: pointer;
}
.list > ul > li > ul > li {
  padding: 10px 20px;
  transition: all 0.2s ease;
}
@media (hover: hover) {
  .list > ul > li > ul > li:hover {
    color: #FFF;
    background-color: #8bcffb;
  }
}
.list > ul > li > ul > li.selected {
  color: #FFF;
  background-color: #5abaf9;
}
.list .items-list {
  display: none;
}
.list .items-list.expanded {
  display: block;
}
.list .items-list .dragging-overlay {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px;
  border: 1px solid #5abaf9;
  background-color: #202020;
}
.list .items-list .dragging-overlay .title,
.list .items-list .dragging-overlay .list {
  background-color: inherit;
}
.list .items-list .btn-wrapper {
  position: relative;
  width: 200px;
}
.list .items-list .btn-wrapper .widget-button-wrapper {
  height: 49px;
}
.list .items-list .btn-wrapper .widget-button-wrapper .widget-button {
  height: 42px;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list .selected {
  background-color: #5abaf9;
}
.list ul {
  list-style: none;
  padding-inline-start: 0px;
  margin: 0px;
}
.list ul li {
  padding: 0px;
}
.toggle-mode {
  border: solid 0px red;
}
.toggle-mode input[type="checkbox"] {
  cursor: pointer;
  position: relative;
  display: inline-block;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  height: 30px;
  width: 110px;
  border-radius: 1.5em;
  background-color: #000;
  border-color: transparent;
  background-clip: padding-box;
  color: #e2e5e5;
  vertical-align: middle;
}
.toggle-mode input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  bottom: 0;
  background-color: white;
  border-radius: 100%;
  border: 0.125em solid transparent;
  background-clip: padding-box;
  transform-origin: right center;
  transform: translateZ(0);
  backface-visibility: hidden;
  z-index: 2;
  width: 23px;
  height: 23px;
  transition: all 0.2s ease;
}
.toggle-mode input[type="checkbox"]::after {
  position: absolute;
  left: 29px;
  top: 2px;
  line-height: 2;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  content: "CONTROL";
  letter-spacing: 0;
  word-spacing: 5px;
  z-index: 1;
}
.toggle-mode input[type="checkbox"]:focus {
  color: white;
  border-color: transparent;
  outline: none;
}
.toggle-mode input[type="checkbox"]:checked {
  color: white;
  background-color: #5abaf9;
  border-color: transparent;
}
.toggle-mode input[type="checkbox"]:checked:after {
  content: "DESIGN";
}
.toggle-mode input[type="checkbox"]:checked::before {
  transform-origin: left center;
  left: calc(100% - 27px);
}
/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-device-width: 768px) and (max-device-width: 1024px) {
  .navigation-list-panels .color-picker-container {
    width: 260px;
  }
  .items-list .item-label,
  .layout-widgets .item-label {
    display: none;
  }
}
* {
  -webkit-user-select: none;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: #000;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #000;
}
::-webkit-scrollbar-thumb {
  background: #585c65;
  border-radius: 10px;
}
html,
body,
#app,
#root {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  background: #161616;
  color: #cdcccc;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  touch-action: none;
}
#app {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  overflow: hidden;
  position: relative;
  outline: none;
}
#app.locked #top-bar {
  pointer-events: none;
}
#app.locked #top-bar .locked-mode {
  pointer-events: all;
}
#app.locked.tabs-open .droppable-root {
  pointer-events: none;
}
#app.locked.tabs-closed .customstack-wrapper:not(.customstack-list) .droppable-root > div {
  pointer-events: none;
}
#app.locked.tabs-closed .customstack-list .navigation-list .navigation,
#app.locked.tabs-closed .customstack-list .navigation-list .droppable-root > div {
  pointer-events: none;
}
.locked-layer {
  width: 100%;
  height: calc(100% - 50px);
  position: fixed;
  top: 50px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 72px;
  color: #0074D0;
}
#tabs-drawer-layout {
  position: absolute;
  top: 50px;
  overflow: hidden;
  width: 400px;
  height: calc(100% - 50px);
  right: auto;
  left: -400px;
  transition: left 0.2s ease;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-device-width: 1366px) {
  #tabs-drawer-layout {
    left: -225px;
    width: 225px;
  }
}
#tabs-drawer-layout > div,
#tabs-drawer-layout > ul {
  width: 100%;
}
#tabs-drawer-layout .tab-content {
  height: 50%;
  overflow: hidden;
}
#tabs-drawer-layout .drawer-nav {
  display: flex;
  height: 34px;
  align-items: center;
  padding-left: 16px;
  font-size: 15px;
  box-sizing: border-box;
}
#tabs-drawer-layout .drawer-nav-tab {
  padding-right: 20px;
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: width 0.3s, right 0.3s;
  white-space: nowrap;
}
#tabs-drawer-layout .drawer-nav-tab:last-child {
  padding-right: 0;
}
#tabs-drawer-layout .drawer-nav-tab:last-child::after {
  content: '';
  position: absolute;
  height: 4px;
  background-color: #5abaf9;
  width: inherit;
  bottom: -6px;
  right: inherit;
}
#tabs-drawer-layout .drawer-nav-tab.active:nth-child(1) ~ li:last-child {
  right: 190px;
  width: 52px;
}
#tabs-drawer-layout .drawer-nav-tab.active:nth-child(2) ~ li:last-child {
  right: 120px;
  width: 26px;
}
#tabs-drawer-layout .drawer-nav-tab.active:nth-child(3) ~ li:last-child {
  right: 72px;
  width: 52px;
}
#tabs-drawer-layout .drawer-nav-tab.active:nth-child(1) {
  width: 52px;
}
#tabs-drawer-layout .drawer-nav-tab.active:nth-child(4) {
  right: 1px;
  width: 70px;
}
#tabs-drawer-layout .nav-list-container {
  height: 100%;
}
#tabs-drawer-layout .nav-list {
  height: calc(100% - 35px);
  overflow-x: hidden;
}
#tabs-drawer-layout .nav-properties {
  border-top: 1px solid #353944;
  overflow: auto;
  height: calc(50% - 35px);
}
#tabs-drawer-layout .nav-properties .properties-title {
  background: #303030;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-top: solid 2px #191919;
}
#tabs-drawer-layout .nav-properties .properties-list {
  margin: 15px 0;
  padding: 0px 5px 0px 15px;
}
#tabs-drawer-layout .nav-properties .properties-list .list-property .properties-field {
  margin-bottom: 5px;
  margin-left: 10px;
}
#tabs-drawer-layout .properties-field {
  display: grid;
  grid-template-columns: 135px auto;
  margin: 0 0 15px;
  padding: 0 10px 0 0;
  align-items: center;
}
#tabs-drawer-layout .properties-field:last-child {
  margin: 0;
}
#tabs-drawer-layout .properties-field .checkbox {
  width: auto;
}
#tabs-drawer-layout .properties-field .dropdown {
  align-self: flex-start;
}
#tabs-drawer-layout .properties-field > label {
  padding: 0 25px 0 0;
}
#tabs-drawer-layout .properties-field.line {
  display: flex;
  align-items: center;
}
#tabs-drawer-layout .properties-field.line label {
  margin: 0 0 5px;
}
#tabs-drawer-layout .properties-field.line .icon {
  cursor: pointer;
  margin-left: 10px;
}
#tabs-drawer-layout .properties-field .vector-btn-group .btn {
  padding: 8px;
}
#tabs-drawer-layout .properties-grid .btn-container {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
#tabs-drawer-layout .properties-grid input {
  width: 100%;
}
#tabs-drawer-layout .properties-grid .property {
  position: relative;
  margin: 10px 0;
  padding: 0 15px 0 15px;
}
#tabs-drawer-layout .properties-grid .property .checkbox {
  position: absolute;
  left: 150px;
  opacity: 0;
  width: 20px;
  height: 20px;
  margin: 0;
  z-index: 1;
  cursor: pointer;
}
#tabs-drawer-layout .properties-grid .property .checkbox + label {
  position: relative;
  padding: 0;
}
#tabs-drawer-layout .properties-grid .property .checkbox + label:before {
  content: '';
  position: absolute;
  left: 100%;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background-color: #191919;
  border: solid 1px #141414;
  box-sizing: border-box;
}
#tabs-drawer-layout .properties-grid .property .checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: calc(100% + 5px);
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}
#tabs-drawer-layout .types-selection {
  margin: 15px 0;
  padding: 0 5px 0 15px;
}
#tabs-drawer-layout .items-list .btn-wrapper,
#tabs-drawer-layout .layout-widgets .btn-wrapper {
  margin: 0 auto;
  width: 200px;
  height: 48px;
  margin: 5x;
  border: 1px solid transparent;
}
#tabs-drawer-layout .items-list .btn-wrapper .widget-button-wrapper,
#tabs-drawer-layout .layout-widgets .btn-wrapper .widget-button-wrapper {
  width: 100%;
  overflow: initial;
}
#tabs-drawer-layout .items-list .btn-wrapper .widget-button-wrapper .widget-button,
#tabs-drawer-layout .layout-widgets .btn-wrapper .widget-button-wrapper .widget-button {
  height: 40px;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
#tabs-drawer-layout .items-list .btn-wrapper .widget-button-wrapper .widget-button:active,
#tabs-drawer-layout .layout-widgets .btn-wrapper .widget-button-wrapper .widget-button:active {
  top: 4px !important;
}
#tabs-drawer-layout .items-list .draggable-widget,
#tabs-drawer-layout .layout-widgets .draggable-widget {
  background-color: #484546;
  border: solid 1px #565656;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  min-height: 38px;
  padding: 0 5px 0 35px;
  align-items: center;
  margin: 10px;
  position: relative;
}
#tabs-drawer-layout .items-list .draggable-widget.selected,
#tabs-drawer-layout .layout-widgets .draggable-widget.selected {
  outline: 1px solid #5abaf9;
  background-color: rgba(90, 186, 249, 0.3);
}
#tabs-drawer-layout .items-list .draggable-widget > img,
#tabs-drawer-layout .layout-widgets .draggable-widget > img {
  position: absolute;
  left: 0;
}
#tabs-drawer-layout .items-list .draggable-widget.control-widget::before,
#tabs-drawer-layout .layout-widgets .draggable-widget.control-widget::before {
  pointer-events: none;
  opacity: 0;
  content: attr(data-tooltip);
  position: absolute;
  top: -20px;
  z-index: 10;
  margin: auto;
  padding: 2px;
  border-radius: 2px;
  background-color: #f0e68c;
  font-size: 12px;
  color: #222;
  transform-origin: bottom;
  transition: opacity 0.2s ease;
  transition-delay: 0s;
}
#tabs-drawer-layout .items-list .draggable-widget.control-widget:hover::before,
#tabs-drawer-layout .layout-widgets .draggable-widget.control-widget:hover::before {
  opacity: 1;
  transition: opacity 0.2s ease;
  transition-delay: 1s;
}
#tabs-drawer-layout .items-list .draggable-widget.widget-in-use,
#tabs-drawer-layout .layout-widgets .draggable-widget.widget-in-use {
  position: relative;
}
#tabs-drawer-layout .items-list .draggable-widget.widget-in-use::after,
#tabs-drawer-layout .layout-widgets .draggable-widget.widget-in-use::after {
  content: '';
  position: absolute;
  left: 18px;
  z-index: 1;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #5abaf9;
}
#tabs-drawer-layout .items-list .draggable-widget label,
#tabs-drawer-layout .layout-widgets .draggable-widget label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px 5px;
  text-align: center;
  cursor: inherit;
  pointer-events: none;
}
#tabs-drawer-layout .items-list .draggable-widget .item-icon,
#tabs-drawer-layout .layout-widgets .draggable-widget .item-icon {
  background-color: #b5b5b5;
  width: 65px;
  min-width: 65px;
  border-radius: 4px;
  height: 25px;
  color: #3f3f40;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: #383536 0px 1px 5px;
  font-size: 11px;
  text-align: center;
  pointer-events: none;
}
#tabs-drawer-layout .items-list .draggable-widget .item-icon img,
#tabs-drawer-layout .layout-widgets .draggable-widget .item-icon img {
  width: 20px;
  filter: invert(1);
  opacity: 0.7;
}
#tabs-drawer-layout .items-list .draggable-widget.dragging,
#tabs-drawer-layout .layout-widgets .draggable-widget.dragging {
  width: 100%;
  background-color: #242424;
  border-color: #5abaf9;
}
#tabs-drawer-layout .items-list .draggable-widget.disabled,
#tabs-drawer-layout .layout-widgets .draggable-widget.disabled {
  background-color: #242424;
}
#tabs-drawer-layout .layout-widgets {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 35px);
  overflow: auto;
}
#tabs-drawer-layout .layout-widgets > div {
  width: 50%;
}
#app.tabs-open #tabs-drawer-layout {
  left: 0;
}
nav {
  background-color: #242424;
}
#panels {
  min-height: 50px;
  grid-column-start: 2;
  grid-row-start: 2;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  width: 100%;
  height: calc(100% - 50px);
  align-items: flex-start;
  transition: all 0.2s ease;
}
#panels.layout-CustomActor {
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}
#panels.layout-Screen,
#panels.layout-Stack {
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}
#panels.layout-1x1 {
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}
#panels.layout-1x2 {
  grid-template-columns: 100%;
  grid-template-rows: 50% 50%;
}
#panels.layout-2x1 {
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
}
#panels .panel {
  height: 100%;
  width: 100%;
  background-color: #161616;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0px;
  overflow-x: hidden;
  font-size: 14px;
  display: flex;
  justify-content: center;
}
.fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.fullscreen .app-icon {
  margin: 20px;
  filter: invert(100%) brightness(104%);
  opacity: 1;
  color: #555;
  font-size: 100px;
}
.fullscreen .app-icon .logo {
  animation: loadingIcon 2s ease-in-out infinite;
}
.fullscreen.login-screen {
  background-color: #000000;
  flex-direction: row;
}
.fullscreen.login-screen .form {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  font-size: 24px;
  padding-left: 50px;
  border-left: solid 1px #303030;
  height: 240px;
}
.fullscreen.login-screen .form input {
  margin-top: 20px;
  font-size: 24px;
}
.fullscreen.login-screen .form div {
  margin-top: 20px;
}
.fullscreen.login-screen .icon-wrapper {
  text-align: center;
  padding-right: 50px;
}
.fullscreen.login-screen .logo {
  animation: none;
}
.opaque {
  margin: 0;
  background-color: #353944;
}
.dropdown {
  position: relative;
}
.dropdown .dropdown-menu {
  position: absolute;
  right: 0px;
  max-height: 0px;
  list-style: none;
  padding-inline-start: 0px;
  width: fit-content;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  background-color: #282b34;
  box-shadow: none;
}
.dropdown .dropdown-menu li {
  white-space: nowrap;
  padding: 10px;
}
.dropdown:hover .dropdown-menu {
  max-height: 200px;
  box-shadow: #121317 0px 2px 5px 1px;
}
#app.tabs-open .customstack-list {
  max-width: 1145px;
}
@media only screen and (max-device-width: 1366px) {
  #app.tabs-open #tabs-drawer-layout {
    width: 280px;
  }
}
#app.tabs-open #panels {
  width: calc(100% - 400px);
  margin: 0 0 0 400px;
}
@media only screen and (max-device-width: 1366px) {
  #app.tabs-open #panels {
    width: calc(100% - 280px);
    margin: 0 0 0 280px;
  }
}
#app.tabs-open #panels .actions {
  opacity: 1;
  pointer-events: unset;
}
input {
  -webkit-user-select: text;
  background-color: #191919;
  border: solid 1px #141414;
  color: #FFF;
  padding: 10px;
}
input:focus {
  outline: none;
}
.btn {
  border: solid 1px #000;
  border-radius: 4px;
  padding: 10px 15px;
  margin: 0px 10px;
}
.btn:focus {
  outline: none;
}
.btn.btn-primary.disabled,
.btn.btn-secondary.disabled,
.btn.btn-danger.disabled,
.btn.disabled {
  background-color: #555555;
  border-color: #272727;
  color: #a6a6a6;
  pointer-events: none;
}
.btn.btn-primary {
  border: solid 1px #000;
  background-color: #5abaf9;
  color: #FFF;
  transition: all 0.2s ease;
}
.btn.btn-primary:hover {
  background-color: #50b6f9;
  cursor: pointer;
}
.btn.btn-secondary {
  border: solid 1px #000;
  background-color: #5a5a5a;
  color: #FFF;
  transition: all 0.2s ease;
}
.btn.btn-secondary:hover {
  background-color: #555555;
  cursor: pointer;
}
.btn.btn-danger {
  border: solid 1px #ba160a;
  background-color: #f44336;
  color: #FFF;
  transition: all 0.2s ease;
}
.btn.btn-danger:hover {
  background-color: #f33a2c;
  cursor: pointer;
}
.btn.selected {
  background-color: #5abaf9;
  color: #FFF;
}
.btn.selected:hover {
  background-color: #5abaf9;
  cursor: pointer;
}
.btn-group .btn {
  margin: 0px;
  border-right: 0px;
  border-radius: 0px;
}
.btn-group .btn:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.btn-group .btn:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: solid 1px #000;
}
.widgets-tab {
  height: 100%;
}
.search-wrapper {
  position: relative;
}
.search-wrapper .fa-search {
  position: absolute;
  outline: solid 0px red;
  top: 10px;
  padding: 0px 10px;
}
.search-wrapper .fa-times-circle {
  position: absolute;
  outline: solid 0px red;
  height: 100%;
  padding: 0px 10px;
  right: 0px;
}
.search-wrapper .list-search {
  width: 100%;
  border: 0px;
  padding-left: 40px;
  background-color: #191919;
  box-sizing: border-box;
}
input:focus {
  outline: 0px;
}
#tabs-drawer-layout .switch.toggle-mode {
  width: 120px;
}
#tabs-drawer-layout .switch.toggle-mode:before {
  content: "Tree";
}
#tabs-drawer-layout .switch.toggle-mode:after {
  content: "Edit Tab";
}
#tabs-drawer-layout .switch input:checked + .slider.inline:before {
  width: 60px;
  transform: translateX(50px);
}
.button-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 10px 0 0;
  overflow-y: auto;
}
.button-list .btn {
  margin: 10px;
}
.safe-image-wrapper {
  width: 100px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.safe-image-wrapper svg {
  min-width: 40px;
  height: 40px;
}
.dropwdown-row .title {
  max-height: 32px;
}
.dropdown-widget {
  position: relative;
  flex-grow: 1;
}
.dropdown-widget .unbound-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 115px;
  z-index: 1;
  margin: auto 0;
  color: #e2ee2d;
}
.dropdown-widget .dropdown {
  width: 100%;
  padding: 0 20px 0 10px;
  height: 25px;
  font-size: 15px;
  border-radius: 4px;
  border-color: #000;
  background-color: #191919;
  color: #FFF;
}
.dropdown-widget .dropdown:focus-visible {
  outline: none;
}
.dropdown-widget select,
.dropdown-widget option {
  -webkit-appearance: none;
}
.dropdown-widget::after {
  content: '\0025BC';
  font: normal normal normal 12px/1 FontAwesome;
  color: white;
  right: 10px;
  top: 0px;
  height: 27px;
  padding: 0px 4px;
  position: absolute;
  pointer-events: none;
  outline: solid 0px red;
  display: flex;
  align-items: center;
}
#tabs-drawer-layout .dropdown-widget .dropdown {
  height: 37px;
  padding: 0 30px 0 10px;
  border: solid 1px #141414;
  border-radius: 0;
}
#tabs-drawer-layout .dropdown-widget::after {
  height: 37px;
  right: 15px;
}
.grip-handle {
  width: 15px;
  height: 18px;
}
.presets-tab {
  height: 100%;
}
.presets-tab .presets-wrapper {
  height: calc(100% - 35px);
  overflow-y: auto;
}
.presets-tab .preset-btn {
  background-color: #484546;
  border: solid 1px #565656;
  border-radius: 4px;
  display: flex;
  min-height: 38px;
  padding: 0px 20px;
  padding-left: 15px;
  align-items: center;
  margin: 10px;
  position: relative;
  width: calc(100% - 55px);
  color: #cdcccc;
  cursor: pointer;
}
.presets-tab .preset-btn .spin-icon {
  visibility: hidden;
}
.presets-tab .preset-btn .spin-icon.visible {
  visibility: visible;
}
.presets-tab .preset-btn .favorite-icon {
  padding: 12px;
  cursor: pointer;
}
.presets-tab .preset-btn .item-icon {
  margin-left: auto;
}
.presets-tab .preset-btn.active {
  color: #FFF;
  background-color: #5abaf9;
  cursor: unset;
}
.presets-tab .preset-btn.active .item-icon {
  display: none;
}
