.sequencer-wrapper {
  .list-wrapper {
    height: 100%;
    border-bottom: 0;
  }

  .search-block {
    .search-field {
      width: 100%;
    }
  }

  .table-wrapper {
    max-height: calc(100% - 70px);
    td {
      padding: 0 15px;
      cursor: default;
    }

    .col {
      flex-grow: 1;
    }
    
    .col-4 {
      width: 50px;
    }
  }
}